import gql from "graphql-tag";

const GET_PARTNER_BY_HOST_NAME = gql`
	query getPartnerByHostName($hostName: String!) {
		getPartnerByHostName(hostName: $hostName) {
			id
			name
			appDomain
			platformFeatures
			parameterizedName
			unlayerToolUrls
			domains
			pagesCnameDomain
      active
			pagesDomain
      links
			logo
      meta
      variables {
        uiTheme
				enchargeWriteKey
				columns
				language
      }
		}
	}
`;

const GET_BASE_PLATFORM_FEATURES_FROM_RAILS = gql`
	query getBasePlatformFeaturesFromRails {
		getBasePlatformFeaturesFromRails
	}
`

const GET_PARTNER_BY_SHORT_CODE = gql`
	query getPartnerByShortCode($shortCode: String!) {
		getPartnerByShortCode(shortCode: $shortCode) {
			id
			name
			appDomain
			platformFeatures
			parameterizedName
			domains
			pagesCnameDomain
      active
			pagesDomain
      links
			logo
      meta
      variables {
				uiTheme
				enchargeWriteKey
				NETLIFY_APP
				APP_DOMAIN
				PAGES_DOMAIN
				FORMS_DOMAIN
				EMAIL_SERVICE_DOMAIN
				ASSETS_S3_DOMAIN
				AWS_SECRET_ACCESS_KEY
			}
			shortCode
			data
			assetsDomains
		}
	}
`;


export { GET_PARTNER_BY_HOST_NAME, GET_BASE_PLATFORM_FEATURES_FROM_RAILS, GET_PARTNER_BY_SHORT_CODE };
