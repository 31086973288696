
import { gql } from '@apollo/client';

export const BUSNISES_PROFILE_FRAGMENT = gql`
  fragment businessProfile on BusinessProfile {
    id
    companyName
    businessEmail
    address1
    address2
    contactNumber
    contactName
    city
    state
    country
    zip
    logo
    favicon
    styles
    socialMedia
    signUpReason
    goals
    industry
    language
    revenue
    steps
    isCompleted
    meta
    image
  }
`;

export const SITE_FRAGMENT = gql`
${BUSNISES_PROFILE_FRAGMENT}
  fragment site on Site {
    id
    broadcastLimitExceeded
    shopify
    wordpress
    weebly
    wix
    domain
    name
    entitlements
    currency
    campaignsCount
    userId
    siteOwnerTrialEligible
    data
    lastPingedAt
    siteOwnerTeamManagementEligible
    membershipsCount
    isSiteOwner
    notificationEmail
    weeklySummary
    dailySummary
    contactsCount
    healthScore
    esVersion
    indexCompletedAt
    indexStartedAt
    integrations {
      id
      type
    }
    businessProfile {
      ...businessProfile
    }
  }
`;


export const LIST_FRAGMENT = gql`
  fragment list on List {
    id
    name
    doubleOptin
    doubleOptinMessage
    locale
    contactsCount
    createdAt
    type
  }
`;

export const SEGMENT_FRAGMENT = gql`
  fragment segment on Segment {
    id
    name
  }
`;

export const TAG_FRAGMENT = gql`
  fragment tag on SiteTag {
    id
    tag
    createdAt
    updatedAt
    subscribersCount
  }
`;

export const EMAILOWNERSHIP_FRAGMENT = gql`
  fragment emailOwnership on EmailOwnership {    
    id
    siteId
    email
    verified
    deliverabilityStatus
    default
    senderName
  }
`;

export const INTEGRATION_FRAGMENT = gql`
  fragment integration on Integration {
    id
    name
    active
    data
    settings
    accountIdentifier
  }`

export const CONNECTED_INTEGRATION_FRAGMENT = gql`
${INTEGRATION_FRAGMENT}
  fragment connectedIntegration on ConnectedIntegration {
    id
    type
    activeListId
    active
    data
    settings
    integration {
      ...integration
    }
  }
  `;

export const EMAIL_FRAGMENT = gql`
  fragment email on Email {
    id
    body
    siteId
    from
    fromName
    emailTemplateId
    delayInterval
    delayPeriod
    delivered
    dropped
    bounced
    complained
    unsubscribed
    clicked
    opened
    failed
    subject
    uniqueOpened
    uniqueClicked
    preheader
    status
    dripCampaignId
    timezone
    sendAt
    sendSchedule
    name
    createdAt
    updatedAt
    json
    template {
      id
      name
      premium
      designFormat
    }
    shortCode
    emailContactsCount
    type
    target
    listIds
    segmentIds
    utmEnabled
    utm
    premiumTools
    data
    complianceReview {
      isStepsLocked
      status
      comments
    }
  }`;

export const LANDING_PAGE_STEP_FRAGMENT = gql`
  fragment landingPageStep on DocumentPage {
    id
    name
    json
    html
    css
    position
    fonts
    type
    landingPage {
      id
      meta
    }
    shopifyPage {
      id
      meta {
        itemsChanged
        designChanged
        allItems
        isShopifyPagePublishing
        storePasswordEnabled
        shopifyItemIds
        unassignedItems
      }
    }
  }
`

export const SHOPIFY_PAGE_FRAGMENT = gql`
  ${LANDING_PAGE_STEP_FRAGMENT}
  fragment shopifyPage on ShopifyPage {
    id
    domain
    siteId
    listId
    name
    urlSlug
    type
    enabled
    status
    updatedAt
    previewUrl
    screenshotUrl
    createdAt
    steps {
      ...landingPageStep
    }
    meta {
      itemsChanged
      designChanged
      allItems
      isShopifyPagePublishing
      shopifyItemIds
      unassignedItems
      storePasswordEnabled
    }
    platformLayoutType
    properties
    siteTagIds
  }
`

export const LANDING_PAGE_FRAGMENT = gql`
${LANDING_PAGE_STEP_FRAGMENT}
  fragment landingPage on LandingPage {
    id
    domain
    domainOwnershipId
    enabled
    listId
    name
    platformLayoutType
    previewUrl
    properties
    seo
    siteId
    state
    status
    contactsCount
    shortCode
    steps {
      ...landingPageStep
    }
    unlayerToolsUsed
    meta
    urlSlug
    shopifyPageUrl
    siteTagIds
  }
`;

export const CUSTOM_FORM_FRAGMENT = gql`
  fragment customForm on CustomForm {
    id
    active
    formFields {
      id
      position
      label
      placeholderText
      required
      showLabel
      backgroundColor
      textColor
      data
      options
      customField {
        id
        type
        deletable
        slug
      }
    }
  }
`

export const WIDGET_STEPS_FRAGMENTS = gql`
fragment widgetSteps on WidgetStep {
    id
    name
    position
    json
    html
    css
    fonts
  }
`

export const WIDGET_TYPE_FRAGMENT = gql`
  fragment widgetType on WidgetType {
    id
    name
  }
`

export const DISPLAY_RULES_FRAGMENT = gql`
  fragment displayRules on DisplayRule {
    id
    name
    url
    matchType
    enabled
    wordpress
  }
`
export const CUSTOM_FIELD_FRAGMENT = gql`
  fragment customField on CustomField {
    id
    slug
    label
    showLabel
    type
    placeholderText
    data
    options
    autoAddToForm
    required
  }
`

export const CAMPAIGN_FOLDER = gql`
  fragment campaignFolder on CampaignFolder {
    id
    name
    campaignGroupRecipes
    campaignGroupId
    siteId
  }
`;